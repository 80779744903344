<template>
    <!-- Main section start -->
	<section class="h-screen px-12 py-32 mb-8 lg:px-32">
		<div class="flex flex-col w-full h-full md:w-1/2 md:justify-center ">
			<!-- Main text start -->
			<h1 class="mb-4 text-5xl font-semibold uppercase xl:text-7xl ">
				<div>From Concept to Code</div> 
				
				<div class="text-3xl text-green-800">Empowering Your Business</div>
			</h1>
			<p class="capitalize xl:w-1/2 text-stone-500">
				Creating Functional and Responsive Web Applications to Empower Your Startup
			</p>
			<!-- Main text end -->

			<!-- Social links start -->
			<div class="flex gap-8 mt-3">
				<a href="#">
					<i class="fa-brands fa-instagram socialIcon"></i>
				</a>
				<a href="#">
					<i class="fa-brands fa-linkedin socialIcon"></i>
				</a>
				<a href="#">
					<i class="fa-brands fa-facebook socialIcon"></i>
				</a>
				<a href="#">
					<i class="fa-brands fa-dribbble socialIcon"></i>
				</a>
				<a href="#">
					<i class="fa-brands fa-pinterest socialIcon"></i>
				</a>
			</div>
			<!-- Social links end -->
		</div>

		<!-- Photographer image start -->
		<div class="absolute bottom-0 right-0 overflow-hidden h-2/5 sm:h-1/2 md:h-3/5 lg:h-4/6 -z-50">
			<img src="@/assets/hero.svg" alt="img" class="h-full">
		</div>

		<!-- Photographer image end -->

		<!-- "More" button start -->
		<!-- <div class="flex items-center justify-start w-full h-20 md:justify-center">
			<a href="#about-section">
				<i class="p-4 text-xl transition rounded-full shadow fa-solid fa-arrow-down text-stone-100 bg-stone-700 hover:-translate-y-2 hover:shadow-xl"></i>
			</a>
		</div> -->
		<!-- "More" button end -->

	</section>
	<!-- Main section end -->
</template>

<script>
export default {

}
</script>

<style>

</style>