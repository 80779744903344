<template>
    <!-- Footer start -->
    <div class="flex flex-col items-center justify-center w-full gap-8 p-8 bg-stone-700 md:justify-evenly md:flex-row">
        <!-- Overview start -->
        <div class="text-center text-stone-100">
            <p class="font-semibold tracking-widest text-stone-100">Madeil</p>
            <p class="font-light tracking-widest text-stone-100">Solutions</p>
        </div>
        <!-- Overview end -->

        <!-- Links start -->
        <div class="flex items-center justify-center gap-8 text-stone-100">
			<a href="#">
				<i class="fa-brands fa-instagram socialIcon"></i>
			</a>
			<a href="#">
				<i class="fa-brands fa-linkedin socialIcon"></i>
			</a>
			<a href="#">
				<i class="fa-brands fa-facebook socialIcon"></i>
			</a>
			<a href="#">
				<i class="fa-brands fa-dribbble socialIcon"></i>
			</a>
			<a href="#">
				<i class="fa-brands fa-pinterest socialIcon"></i>
			</a>
        </div>
        <!-- Links end -->
    </div>
    <!-- Footer end -->
</template>

<script>
export default {

}
</script>

<style>

</style>